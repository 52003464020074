import InlineBodyTransform from './inlinebodytransform';
import { MDBCard } from 'mdbreact';
import React from 'react';

const extrabox = (props) => {
    return(
        <div className="extrabox">
            {props.extrabox.map((extraboxItem, key) => {
                return(
                    <MDBCard className="p-2" key={key}>
                        <InlineBodyTransform bodyValue={extraboxItem} key={key}/>
                    </MDBCard>
                )
            })}
        </div> 
    )
}

export default extrabox;