import { MDBBtn, MDBCard } from 'mdbreact'

import { GatsbyImage } from 'gatsby-plugin-image'
import InlineBodyTransform from '../inlinebodytransform'
import React from 'react'
import styled from 'styled-components'

let gmdv = require(`../../ghc_config`)

const MDBBtnComp = styled(MDBBtn)`
  padding: 2px;
  min-height: 110px;
  background-color: #ffffff !important;
  div {
    display: block;
    width: 100%;
    .leftImage {
      float: left;
      width: 40%;
      padding: 2px;
    }
    .rightTitle {
      float: right;
      width: 60%;
      padding: 2px;
    }
  }
  h3 {
    font-size: 1em;
    background-color: #ee1c25 !important;
    text-transform: none;
    font-weight: bold;
    padding: 2px;
  }
  p {
    color: #797563;
    text-transform: none;
    text-align: left;
    font: italic 0.9em/1.4em Georgia, Times, Serif;
  }
  .card {
    padding: 2px;
  }
  @media only screen and (max-width: 1199px) {
    div {
      .leftImage {
        width: 50%;
      }
      .rightTitle {
        width: 50%;
      }
    }
    p {
      font: italic 0.86em/0.9em Georgia, Times, Serif;
    }
  }
  @media only screen and (max-width: 991px) {
    div {
      .leftImage {
        width: 30%;
      }
      .rightTitle {
        width: 70%;
      }
    }
    p {
      font: italic 1.2em/1.6em Georgia, Times, Serif;
    }
  }
  @media only screen and (max-width: 767px) {
    div {
      .leftImage {
        width: 30%;
      }
      .rightTitle {
        width: 70%;
      }
    }
    p {
      font: italic 1.2em/1.6em Georgia, Times, Serif;
    }
  }
`

const Brochure = (props) => {
  const title = props.description.value.replace(/<[^>]*>?/gm, '')
  let Category = ``
  props.category != undefined
    ? (Category = `a ${gmdv.selectBrochure(props.category)}`)
    : null
  return (
    <MDBCard>
      <MDBBtnComp href={props.url} target="_blank" color="white">
        <h3>Download {Category}</h3>
        <div>
          <div className="leftImage">
            <img src={encodeURI(props.iconImage)} alt="" />
          </div>
          <div className="rightTitle">
            <InlineBodyTransform bodyValue={props.description} />
          </div>
        </div>
      </MDBBtnComp>
    </MDBCard>
  )
}

export default Brochure
