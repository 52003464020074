import InlineBodyTransform from '../inlinebodytransform';
import React from 'react';

let gmdv = require(`../../ghc_config`);

const Video = (props) => {
  let videoId = props.videoItem.field_brightcovevideo_id.toString().trim()
  return (
    <>
      <iframe 
        title={videoId}
        id={videoId}
        src={gmdv.getBcString(videoId)}
        allowFullScreen="" 
        allow="encrypted-media" 
        className="brightcove-react-player-loader-genesis"
        ></iframe>
      <h2>{props.videoItem.field_title}</h2>
      <InlineBodyTransform bodyValue={props.videoItem.field_video_description} />
    </>
  ) 
}

export default Video;