import Brochure from './brochure'
import React from 'react'

const BrochureList = (props) => {
  const post = props.data
  return post.relationships
    ? post.relationships.field_brochures
      ? post.relationships.field_brochures.map((brochureItem, key) => {
          if (brochureItem.relationships.field_pdf) {
            return (
              <Brochure
                key={key}
                category={brochureItem.field_category}
                url={brochureItem.relationships.field_pdf.localFile.publicURL}
                iconImage={
                  brochureItem.relationships.field_image_icon.localFile
                    .childImageSharp.imageIcon.images.fallback.src
                }
                description={brochureItem.field_description}
              />
            )
          }
        })
      : null
    : null
}

export default BrochureList
