import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import BreadcrumbMenu from '../../components/menus/bcmenu';
import Brochures from '../../components/pdfdocs/brochureslist';
import Extrabox from '../../components/extrabox';
import InlineBodyTransform from '../../components/inlinebodytransform';
import Layout from '../../components/layout';
import React from 'react';
import SEO from '../../components/seo';
import SideNavBar from '../../components/menus/navbarmenu';
import Videos from '../../components/videos/brightcovevideoserviceslist';
import { graphql } from 'gatsby';

let gmdv = require(`../../ghc_config`);

const Detail = ({ data, location }) => {

  //  Menu relation
  let menuName = gmdv.getMenuid(location)

  //  Page
  const post = data.nodeYourStay
  let backgroundImage = ''
  if (post.relationships.field_image != null) {
    backgroundImage = post.relationships.field_image.localFile.childImageSharp.yourstay.src
    backgroundImage = encodeURI(backgroundImage)
  }
  const boxes = post.field_specific_extra_block_on_rg

  return (
    <Layout>
      <SEO 
        title={post.title}
        description={`Genesis HealthCare offers long term care services, short stay care, transitional care services, rehab services and specialty services`}
        keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol xs="12" md="3" lg="3" className="col-12 leftside">
              <SideNavBar menuName={menuName} location={location}/>
            </MDBCol>
            <MDBCol xs="12" md="9" lg="9" className="col-12 contentRight">
              <h1 className="pagetitle" 
                style={{backgroundImage: `url(${backgroundImage})`}}>
                {post.title}
              </h1>
              <BreadcrumbMenu menuName={menuName} location={location}/>
              {(((post.relationships.field_brochures.length > 0 &&
              !post.relationships.field_brochures[0].field_description)
              || post.relationships.field_brochures.length === 0)
              && (!boxes || boxes.length === 0)) ?
                <MDBRow center>
                  <MDBCol xs="12" md="12" lg="12" className="col-12 px-3">
                    <InlineBodyTransform bodyValue={post.body} />
                    <Videos data={post}/>
                  </MDBCol>
                </MDBRow>
                :<MDBRow center>
                  <MDBCol xs="12" md="12" lg="8" className="col-12 px-3">
                    <InlineBodyTransform bodyValue={post.body} />
                  </MDBCol>
                  <MDBCol xs="12" md="12" lg="4" className="col-12 px-3">
                    <Brochures data={post} />
                    {boxes && boxes.length > 0 ? <Extrabox extrabox={boxes} /> : null }
                  </MDBCol>
                </MDBRow>}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export default Detail;

export const query = graphql`
  query($id: String!) {
    nodeYourStay(id: { eq: $id }) {
      title
      body {
        value
      }
      field_specific_extra_block_on_rg {
        value
      }
      created
      relationships {
        field_image {
          localFile{
            childImageSharp {
              yourstay: original {
                src
              }
            }
          }
        }
        field_brochures {
          field_description {
            value
          }
          field_category
          relationships {
            field_image_icon {
              localFile {
                childImageSharp {
                  imageIcon: gatsbyImageData(layout: FIXED width: 64)
                }
              }
            }
            field_pdf {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }

    # Menu relation
    allMenuLinkContentMenuLinkContent {
      edges {
        node {
          title
          link {
            uri
          }
          menu_name
        }
      }
    } 
  }
`