import React from 'react';
import Video from './brightcovevideos';

const VideoServices = (props) => {
    const post = props.data
    
    return(
        post.relationships ? 
            post.relationships.field_video ?
                post.relationships.field_video.map((videoItem, key) => {
                    if (videoItem.relationships.field_thumbnailurl) {
                        return(<Video videoItem={videoItem} key={key }/>)
                    }
                })
            : null 
        : null
    )
}

export default VideoServices;